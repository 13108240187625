<template>
  <section>
    <!--CALENDAR-->
    <b-card class="app-calendar overflow-hidden border">
      <div class="row no-gutters">
        <!-- Calendar -->
        <div class="col position-relative">
          <b-form-group :label="$t('dashboard.trip.calendar_date')" label-for="start-at-calendar">
            <b-form-datepicker
              id="start-at-calendar"
              v-model="dateCalendarSelected"
              :locale="$i18n.locale"
              :no-flip="true"
              :placeholder="$t('dashboard.trip.calendar_date')"
              class="mb-1"
            />
          </b-form-group>
          <!--CALENDAR-->
          <full-calendar
            ref="calendarSlot"
            :options="calendarOptions"
            :plugins="calendarOptions.plugins"
            class="full-calendar"
          />
        </div>
      </div>
    </b-card>
    <!-- SIDEBAR TRIP INFO -->
    <SidebarTripResume
      v-model="isTripResumeSidebarActive"
      :round-trip="roundTripSelected"
    />
  </section>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'
import momentPlugin from '@fullcalendar/moment'

import UIHelper from '@/helpers/ui'
import SidebarTripResume from '@/views/trip/sidebar/SidebarTripResume.vue'
import { fetchTripsCalendarRequest } from '@/request/globalApi/requests/tripRequest'

export default {
  name: 'TripCalendar',

  components: {
    FullCalendar,
    SidebarTripResume,
  },

  mixins: [UIHelper],
  props: {
    params: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      trips: [],
      displayedDates: {},
      isTripResumeSidebarActive: false,
      roundTripSelected: {},
      dateCalendarSelected: '',
      dateSlotSelected: '',
      timeSlotSelected: '',
      tripSelected: null,
      currentDate: null,
      calendarOptions: {
        plugins: [momentPlugin, dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
        initialView: this.$route.query.mode,
        allDaySlot: false,
        headerToolbar: {
          start: 'sidebarToggle, prev, next, title',
          end: 'timeGridWeek,listMonth,today',
        },
        initialDate: this.$moment(this.$route.query.start).endOf('isoWeek').format('YYYY-MM-DD'),
        events: [],
        datesSet: event => {
          const getDay = day => (day ? this.$moment(day).format('YYYY-MM-DD') : null)
          if (getDay(event.start) === this.displayedDates.start && this.displayedDates.mode === event.view.type) return

          this.displayedDates = {
            start: getDay(event.start),
            end: getDay(event.end),
            mode: event.view.type,
          }
          this.$router.replace({ query: { ...this.$route.query, ...this.displayedDates, end: undefined } })
          this.fetchTripsCalendar({ start: getDay(event.start), end: getDay(event.end) })
        },
        selectable: true,
        eventClick: trip => {
          this.roundTripSelected = trip.event
          setTimeout(() => {
            this.isTripResumeSidebarActive = true
          }, 100)
        },
        textEscape: false,
        eventResizableFromStart: true,
        fixedWeekCount: false,
        weekNumberCalculation: 'ISO',
        dragScroll: true,
        navLinks: true,
        eventTimeFormat: {
          hour: '2-digit',
          minute: '2-digit',
          hourCycle: 'h23',
          meridiem: false,
        },
        slotLabelFormat: {
          hour: '2-digit',
          minute: '2-digit',
          hourCycle: 'h23',
          meridiem: false,
        },
        dayHeaderFormat: 'dd, D MMM',
        locale: this.$i18n.locale,
      },
      eventSkeleton: {
        id: null,
        title: '',
        start: null,
        end: null,
        allDay: false,
        color: '#DCC181',
        textColor: 'white',
        extendedProps: null,
      },
    }
  },
  watch: {
    dateCalendarSelected(val) {
      this.displayedDates.start = val
      const calendarApi = this.$refs.calendarSlot.getApi()
      calendarApi.gotoDate(val)
    },
  },
  methods: {
    // Create calendar event for each trip
    buildEvent() {
      this.calendarOptions.events = []
      const roundTrips = []
      this.trips.forEach(trip => {
        trip.tripSteps.forEach(step => {
          const event = JSON.parse(JSON.stringify(this.eventSkeleton))
          let departure = null
          let arrival = null
          if (trip.tripType === 'flight') {
            departure = step.flightDeparture
            arrival = step.flightArrival
          } else {
            departure = step.transferDeparture
            arrival = step.transferArrival
          }
          roundTrips.push(departure, step)
          event.id = step.id
          event.groupId = step.id
          event.title = `${departure.address.addressName || departure.address.address} > ${arrival.address.addressName || arrival.address.address}`
          // Start
          event.start = departure.startsAt
          event.startTime = this.$options.filters.timeFormatUtc(departure.eventDateTime)
          // End
          event.end = arrival.endsAt
          event.endTime = this.$options.filters.timeFormatUtc(arrival.endsAt)

          event.extendedProps = {
            trip,
            step,
            departure,
            arrival,
          }
          this.calendarOptions.events.push(event)
        })
      })
      if (this.calendarOptions && this.calendarOptions.events && this.calendarOptions.events[0] && this.calendarOptions.events[0].start) {
        this.dateCalendarSelected = this.calendarOptions.events[0].start
      }
    },
    fetchTripsCalendar(query) {
      const params = {
        ...this.params,
        ...query,
        getAllItems: true,
      }
      fetchTripsCalendarRequest(params)
        .then(response => {
          const { trips, totalItems } = response.data
          this.totalItems = totalItems
          this.trips = trips
          this.buildEvent()
        })
        .finally(() => {
          this.tripsLoading = false
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/apps/calendar.scss';

.airport-info-wrapper {
  max-height: 950px;
  overflow: auto;
}

.fc-daygrid-body.fc-daygrid-body-unbalanced {
  width: 100% !important;
}

.fc-scrollgrid-sync-table {
  width: 100% !important;
}

.fc-col-header {
  width: 100% !important;
}

.fc-selectDateBtn-button {
  margin-left: 2rem !important;
  border-color: #dcc181 !important;
  background-color: #dcc181 !important;
}
</style>
