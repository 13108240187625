<template>
  <section>
    <!-- FILTERS DATE-->
    <b-row class="mb-1">
      <b-col class="d-flex justify-content-end" cols="12">
        <span class="d-flex justify-content-end align-items-center">
          <app-checkbox-calendar
            id="calendar-mode"
            v-model="calendarMode"
            class="mb-0"
            @change="switchCalendarMode()"
          />
        </span>

        <span>
          <b-button v-if="$can('TRIP_ADD')" :to="{ name: 'trip-edit' }" class="ml-1">
            <font-awesome-icon class="mr-50" icon="plane" />
            {{ $t('trip.action.add_trip') }}
          </b-button>
        </span>
      </b-col>
    </b-row>
    <b-card>
      <b-row>
        <!--Filter : Name-->
        <b-col v-if="selectedOrganization.buyer" md="3">
          <app-input id="search" v-model="params.name" :label="$t('trip.name')" />
        </b-col>
        <b-col v-if="selectedOrganization.seller" md="3">
          <app-input id="search" v-model="params.customerName" :label="$t('trip.customer_name')" />
        </b-col>
        <!--Filter : Aircraft -->
        <b-col md="3">
          <b-form-group :label="$t('trip.event.aircraft')" label-for="start-at">
            <v-select
              v-model="params.organizationAircraft"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="organizationAircrafts"
              :placeholder="$t('trip.event.aircraft')"
              :reduce="val => val.id"
              clearable
              label="nameInfo"
            />
          </b-form-group>
        </b-col>
        <!--Filter : Type Aeronef-->
        <b-col v-if="$can('ORGANIZATION_ACCOUNT_AIRCRAFT_VIEW')" md="3">
          <b-form-group :label="$t('trip.event.aircraft_type')" label-for="event-type-aeronef">
            <v-select
              id="event-type-aeronef"
              v-model="params.organizationAircraftType"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="organizationAircraftTypes"
              :placeholder="$t('trip.event.aircraft_type')"
              label="value"
            />
          </b-form-group>
        </b-col>
        <!--Filter : Flight Mission -->
        <b-col md="3">
          <enum-select
            id="event-flight-mission"
            v-model="params.flightMission"
            :label="$t('trip.event.flight_mission')"
            enum-class="FlightMission"
          />
        </b-col>
        <!--Filter : Crew -->
        <b-col md="3">
          <b-overlay v-if="selectedOrganization.buyer" :show="loadingCrewMembers">
            <b-form-group :label="$t('trip.event.crew_members')" label-for="event-crew-members">
              <v-select
                id="event-crew-members"
                v-model="params.organizationCrewMember"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="organizationCrewMembers"
                :placeholder="$t('trip.event.crew_members')"
                :reduce="val => val.id"
                label="fullName"
              />
            </b-form-group>
          </b-overlay>
        </b-col>
        <b-col v-if="!calendarMode" md="3">
          <b-form-group :label="$t('common.start_at')" label-for="start-at">
            <b-form-datepicker id="start-at" v-model="params.start" :locale="$i18n.locale" :max="params.end" size="md" />
          </b-form-group>
        </b-col>
        <b-col v-if="!calendarMode" md="3">
          <b-form-group :label="$t('common.end_at')" label-for="end-at">
            <b-form-datepicker id="en-at" v-model="params.end" :locale="$i18n.locale" :min="params.start" size="md" />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>

    <!-- TODO: What !? trips.totalItems on array? This condition is falsy forever -->
    <div v-if="!trips.length && trips.totalItems === 0 && !tripsLoading">
      <b-row>
        <b-col cols="12">
          <b-alert class="m-0" show variant="primary">
            <div class="alert-body">
              <span>{{ $t('alert.trip.no_trip_found') }}</span>
              <b-link v-if="$can('TRIP_ADD')" :to="{ name: 'trip-edit' }" class="alert-link">
                {{ $t('trip.action.add_trip') }}
              </b-link>
            </div>
          </b-alert>
        </b-col>
      </b-row>
    </div>
    <!-- LIST MODE -->
    <div v-else>
      <b-overlay :show="tripsLoading" spinner-medium>
        <div>
          <!--TRIP CLASSIC MODE-->
          <b-row v-if="!calendarMode" class="mt-1">
            <b-col v-for="trip in trips" :key="trip.id" md="12">
              <b-card class="border rounded">
                <trip-view :trip-data="trip" class="mb-1" />
                <trip-leg-collapsible
                  :action-mode="false"
                  :trip-data="trip"
                  :trip-steps="trip.tripSteps"
                  :trip-step-events="tripStepEvents"
                  @refetch-data="refetchData()"
                />
              </b-card>
            </b-col>
          </b-row>
          <!--LIST LEGS-->
          <b-row v-else>
            <b-col md="12">
              <trip-calendar :params="params" />
            </b-col>
          </b-row>

          <!-- PAGINATION -->
          <app-data-table-bottom
            v-if="totalItems"
            :page="params.page"
            :per-page="params.numberOfItemsPerPage"
            :total-count="totalItems"
            class="mx-2 mb-2"
            table-name="category-products-name"
            @pagination="params.page = $event"
          />
        </div>
      </b-overlay>
    </div>
  </section>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import { mapState } from 'vuex'

import AppCheckboxCalendar from '@/components/AppCheckboxCalendar.vue'
import AppDataTableBottom from '@/components/AppDataTableBottom.vue'
import UIHelper from '@/helpers/ui'
import TripView from '@/views/trip/TripView.vue'
import TripCalendar from '@/views/trip/TripCalendar.vue'
import TripLegCollapsible from '@/views/trip/leg/TripLegCollapsible.vue'
import {
  fetchAircraftFilters,
  fetchTripOrganizationInfosRequest,
  fetchTripsRequest,
} from '@/request/globalApi/requests/tripRequest'
import { fetchCrewMembersRequest } from '@/request/globalApi/requests/crewMemberRequests'
import CrewMember from '@/models/CrewMember'

export default {
  name: 'TripList',

  components: {
    TripLegCollapsible,
    AppCheckboxCalendar,
    AppDataTableBottom,
    TripView,
    TripCalendar,
  },
  directives: {
    Ripple,
  },
  mixins: [UIHelper],
  data() {
    return {
      organizationAircraftTypes: [],
      // $route.query.calendar === null if it's calendarMode
      calendarMode: this.$route.query.calendar === null,
      organizationAircrafts: [],
      organizationCrewMembers: [],
      loadingCrewMembers: false,
      trips: [],
      totalItems: 0,
      params: {
        // PAGE
        numberOfItemsPerPage: 10,
        page: 1,
        // SEARCH
        name: null,
        customerName: null,
        start: null,
        end: null,
        flightMission: null,
        organizationAircraftType: null,
        organizationAircraft: null,
        organizationCrewMember: null,
      },
      sortBy: null,
      sortDesc: null,
      tripsLoading: true,
      tripStepEvents: [],
    }
  },
  computed: {
    ...mapState('auth', ['selectedOrganization']),
    allFilters() {
      return {
        numberOfItemsPerPage: this.params.numberOfItemsPerPage,
        page: this.params.page,
        // SEARCH
        name: this.params.name,
        customerName: this.params.customerName,
        start: this.params.start,
        end: this.params.end,
        flightMission: this.params.flightMission,
        organizationAircraftType: this.params.organizationAircraftType,
        organizationAircraft: this.params.organizationAircraft,
        organizationCrewMember: this.params.organizationCrewMember,
      }
    },
  },
  watch: {
    allFilters: {
      handler() {
        this.refetchData()
      },
      deep: true,
    },
  },
  mounted() {
    this.APIFetchTrips()
    this.APIFetchAircraftFilters()
    if (this.$can('CREW_VIEW')) {
      this.APIFetchOrganizationCrewMembers()
    }
  },
  methods: {
    switchCalendarMode() {
      if (this.calendarMode) {
        this.$router.replace({ name: 'trips', query: { calendar: null } })
      } else {
        this.$router.replace({ name: 'trips' })
      }
    },
    APIFetchTripOrganizationInfosRequest(trip) {
      fetchTripOrganizationInfosRequest(trip.id)
        .then(response => {
          const { tripStepEvents } = response.data
          this.$set(trip, 'tripStepEvents', tripStepEvents)
          this.tripStepEvents[trip.id] = tripStepEvents
        })
    },
    async APIFetchTrips() {
      this.tripsLoading = true
      fetchTripsRequest(this.params)
        .then(response => {
          const { trips, totalItems } = response.data
          this.totalItems = totalItems
          this.trips = trips
          // TODO: Request this only on change select => replace emit 'bv::toggle::collapse' by an classic event
          this.trips.forEach(trip => {
            if (this.selectedOrganization.id !== trip.organization.id) {
              this.APIFetchTripOrganizationInfosRequest(trip)
            }
          })
        })
        .finally(() => {
          this.tripsLoading = false
        })
    },
    deleteTrip(tripId) {
      this.$swal({
        title: this.$t('alert.delete_confirmation.title'),
        text: this.$t('alert.delete_confirmation.message'),
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: this.$t('alert.delete_confirmation.cancel'),
        confirmButtonText: this.$t('alert.delete_confirmation.approve'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-dark ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('trip/removeTrip', tripId).then(() => {
            this.$refs.refTripListTable.refresh()
            this.alert(this.$t('api.alert.form.saved_successfully'))
          })
        }
      })
    },
    APIFetchAircraftFilters() {
      fetchAircraftFilters().then(response => {
        this.organizationAircraftTypes = response.data.aircraftTypes
        this.organizationAircrafts = response.data.aircrafts.map(aircraft => {
          const nameInfo = `${aircraft.callSign} - ${aircraft.registration}`
          return { ...aircraft, nameInfo }
        })
      })
    },
    APIFetchOrganizationCrewMembers() {
      this.loadingCrewMembers = true
      fetchCrewMembersRequest()
        .then(response => {
          const { crewMembers } = response.data
          this.organizationCrewMembers = crewMembers.map(member => new CrewMember(member))
        })
        .finally(() => {
          this.loadingCrewMembers = false
        })
    },
    /* UPDATE ARRAY */
    refetchData() {
      this.APIFetchTrips()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
