/* eslint-disable class-methods-use-this */

// Validation rules

import {
  deleteCrewMemberRequest,
  editCrewMemberRequest,
  fetchCrewMemberRequest,
  postCrewMemberRequest,
} from '@/request/globalApi/requests/crewMemberRequests'
import BaseModel from './_Base'

/**
 * CrewMember model
 * @link https://vuemc.io/#basic-usage
 * @example use in /src/views/crew/CrewList/sidebar/SidebarCrewForm.vue
 */
class CrewMember extends BaseModel {
  // Default attributes that define the "empty" state.
  defaults() {
    return {
      id: null,
      fullName: null,
      sex: null,
      nationalityCountry: { code: null },
      organizationAircraft: [],
      organizationAircraftIds: [],
    }
  }

  //--------------------------------
  //        API REQUESTS
  //--------------------------------

  async fetch(memberId = this.id) {
    return fetchCrewMemberRequest(memberId).then(({ data }) => {
      const { crewMember } = data
      this.set({
        ...crewMember,
        organizationAircraftIds: crewMember.organizationAircrafts.map(aircraft => aircraft.id),
      })
      this.sync()
    })
  }

  async post() {
    const payload = {
      fullName: this.fullName,
      sex: this.sex,
      nationalityCountryCode: this.nationalityCountry.code,
      organizationAircraftIds: this.organizationAircraftIds,
    }
    return postCrewMemberRequest(payload)
  }

  async patch() {
    const payload = {
      id: this.id,
      fullName: this.fullName,
      sex: this.sex,
      nationalityCountryCode: this.nationalityCountry.code,
      organizationAircraftIds: this.organizationAircraftIds,
    }
    await editCrewMemberRequest(payload)
    this.sync()
  }

  async delete() {
    return deleteCrewMemberRequest(this.id, this.name)
  }
}

export default CrewMember
