<template>
  <section>
    <b-row>
      <!--NAME-->
      <b-col md="4">
        <div class="d-flex align-items-center">
          <b-link :disabled="!$can('TRIP_VIEW')" :to="{ name: 'trip-view', params: { trip_id: tripData.id } }" class="h3 mb-0 mr-1">
            <span v-if="tripData.organization.id === selectedOrganization.id">{{ tripData.name }}</span>
            <span v-else>{{ tripData.organization.legalName }}</span>
          </b-link>
          <!--TODO: trip creator exist? -->
          <!-- <span v-if="false">
            <span class="font-small-3">
              {{ $t('common.created_by') }}
            </span>
            <b-link>
              {{ tripData.creator }}
            </b-link>
          </span> -->
        </div>
      </b-col>
      <!--BASE BTN-->
      <b-col class="d-flex justify-content-end" md="3">
        <div class="d-flex">
          <!-- TODO -->
          <!-- <b-button v-if="false && $can('TRIP_ACCOUNTING_VIEW')" class="mr-50">
            <font-awesome-icon class="mr-50" icon="receipt" />
            {{ $t('trip.action.show_orders') }}
          </b-button> -->
          <!-- TODO -->
          <!-- <b-button v-if="false && $can('TRIP_VIEW')">
            <font-awesome-icon class="mr-50" icon="file-pdf" />
            {{ $t('action.export') }}
          </b-button> -->
        </div>
      </b-col>

      <b-col md="5">
        <trip-leg-selector :trip-data="tripData" />
      </b-col>
    </b-row>
    <!--SUBTEXT FLIGHT-->
    <b-row>
      <b-col md="6">
        <!--IF AIRCRAFT-->
        <div v-if="tripData.tripType === 'flight'">
          <span>
            <font-awesome-icon class="mr-50" icon="plane" />
            {{ tripData.organizationAircraft.type }} - {{ tripData.organizationAircraft.name }}

            <!-- Call sign - Registration -->
            <b-badge v-if="tripData.organizationAircraft.callSign || tripData.organizationAircraft.registration" variant="light-secondary" class="ml-2">
              <span v-if="tripData.organizationAircraft.callSign">{{ tripData.organizationAircraft.callSign }}</span>
              <span v-if="tripData.organizationAircraft.registration"> - {{ tripData.organizationAircraft.registration }}</span>
            </b-badge>
          </span>
        </div>

        <!--IF HELICOPTER-->
        <div v-else>
          <font-awesome-icon class="mr-50" icon="helicopter" />
          <span>{{ $t('trip.type.helicopter_only') }}</span>
        </div>

        <!--LIST DEPARTURE/ARRIVAL-->
        <section :to="{ name: 'trip-view', params: { trip_id: tripData.id } }" class="mt-1">
          <div v-for="(tripStep, index) in tripData.tripSteps.filter(step => !!step.flightDeparture)" :key="index">
            <b-link @click="scrollIntoEvent(tripStep.id)">
              <b-breadcrumb class="p-0">
                <b-breadcrumb-item v-if="tripData.tripType === 'flight'">
                  {{ tripStep.flightDeparture.airport ? tripStep.flightDeparture.airport.name : tripStep.flightDeparture.address.address }} -
                  {{ tripStep.flightDeparture.airport ? tripStep.flightDeparture.airport.icaoCode : '' }}
                </b-breadcrumb-item>
                <b-breadcrumb-item v-if="tripData.tripType === 'flight'">
                  {{ tripStep.flightArrival.airport ? tripStep.flightArrival.airport.name : tripStep.flightArrival.address.address }} -
                  {{ tripStep.flightArrival.airport ? tripStep.flightArrival.airport.icaoCode : '' }}
                </b-breadcrumb-item>
                <b-breadcrumb-item v-if="tripData.tripType === 'transfer'">
                  {{ tripStep.transferDeparture.airport ? tripStep.transferDeparture.airport.name : tripStep.transferDeparture.address.address }} -
                  {{ tripStep.transferDeparture.airport ? tripStep.transferDeparture.airport.icaoCode : '' }}
                </b-breadcrumb-item>
                <b-breadcrumb-item v-if="tripData.tripType === 'transfer'">
                  {{ tripStep.transferArrival.airport ? tripStep.transferArrival.airport.name : tripStep.transferArrival.address.address }} -
                  {{ tripStep.transferArrival.airport ? tripStep.transferArrival.airport.icaoCode : '' }}
                </b-breadcrumb-item>
              </b-breadcrumb>
            </b-link>
          </div>
        </section>
      </b-col>
      <b-col class="d-flex justify-content-end align-items-end" md="6">
        <b-button
          v-if="$can('TRIP_VIEW')"
          :to="{ name: 'trip-view', params: { trip_id: tripData.id } }"
          class="mr-1 d-flex align-items-center"
          size="sm"
          variant="outline-primary"
        >
          <font-awesome-icon class="mr-50" icon="plus" />
          {{ $t('timeline.details.show') }}
        </b-button>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import AccessControl from '@/helpers/accessControl'
import UIHelper from '@/helpers/ui'
import TripLegSelector from '@/views/trip/leg/TripLegSelector.vue'
import { mapState } from 'vuex'

export default {
  name: 'TripView',

  components: {
    TripLegSelector,
  },
  mixins: [UIHelper, AccessControl],
  props: {
    tripData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      showDetails: true,
      tripStepEvents: [],
    }
  },
  computed: {
    ...mapState('auth', ['selectedOrganization']),
  },
  methods: {
    deleteTrip() {
      this.$store.dispatch('trip/removeTrip', this.tripData.id).then(() => {
        this.sendFormSuccess()
        this.$router.push({ name: 'trips' })
      })
    },
    confirmDeleteTrip() {
      this.$swal({
        title: this.$t('alert.delete_confirmation.title'),
        text: this.$t('alert.delete_confirmation.message'),
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: this.$t('alert.delete_confirmation.cancel'),
        confirmButtonText: this.$t('alert.delete_confirmation.approve'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-dark ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.deleteTrip()
        }
      })
    },
    sendFormSuccess() {
      this.$swal({
        icon: 'success',
        title: this.$t('api.alert.form.saved_successfully'),
        text: '',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
    scrollIntoEvent(eventId) {
      const el = document.getElementById(`event-${eventId}`)
      if (el) {
        el.scrollIntoView({
          behavior: 'smooth',
        })
      }
    },
  },
}
</script>
