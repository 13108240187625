<template>
  <b-sidebar id="sidebar-addPassenger" :visible="value" @change="val => $emit('input', val)">
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex align-items-center content-sidebar-header w-100">
        <b-button v-if="$can('TRIP_VIEW')" variant="flat-primary" class="mr-50" size="sm" :to="{ name: 'OrdersManager' }">
          <font-awesome-icon class="mr-50" icon="receipt" />
          {{ $t('trip.action.show_orders') }}
        </b-button>
        <!-- <b-button variant="flat-primary" size="sm">
          <font-awesome-icon icon="file-pdf" class="mr-50" />
          {{ $t('action.export') }}
        </b-button> -->

        <feather-icon class="mr-1 ml-auto cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>
      <!-- RESUME -->
      <b-card-title v-if="roundTrip.extendedProps.trip.name" class="h1 text-center mx-2 mt-2">
        {{ roundTrip.extendedProps.trip.name }}
      </b-card-title>
      <b-card-sub-title v-if="roundTrip.extendedProps.trip.creator && roundTrip.extendedProps.trip.creator.name" class="text-center">
        <span class="font-small-3">{{ $t('common.created_by') }} </span>
        <span>{{ roundTrip.extendedProps.trip.creator.name }}</span>
      </b-card-sub-title>

      <div class="mt-3 mb-1 mx-2">
        <section class="mb-1">
          <div v-if="roundTrip.extendedProps.trip.organizationAircraft">
            <span>
              <font-awesome-icon icon="plane" class="mr-50" />
              {{ roundTrip.extendedProps.trip.organizationAircraft.type }} - {{ roundTrip.extendedProps.trip.organizationAircraft.name }}
            </span>
          </div>
          <!--SUBTEXT AIRCRAFT-->
          <div v-else>
            <font-awesome-icon icon="helicopter" class="mr-50" />
            <span>{{ $t('trip.type.helicopter_only') }}</span>
          </div>
        </section>

        <!--DEPARTURE TO / ARRIVAL FROM (celler only) -->
        <section v-if="$store.state.auth.selectedOrganization.seller" class="mb-1">
          <b-overlay :show="isLoading">
            <div v-if="tripStepEvent.className && tripStepEvent.className.includes('Departure')">
              <div v-if="!isLoading" class="font-weight-bold">{{ $t('trip.departure_to') }} :</div>
              <font-awesome-icon icon="plane" class="mx-50" />
              <span v-if="!isLoading">{{ roundTrip.extendedProps.arrival.address.addressName || roundTrip.extendedProps.arrival.address.address }}</span>
            </div>
            <div v-else-if="tripStepEvent.className && tripStepEvent.className.includes('Arrival')">
              <div class="font-weight-bold">{{ $t('trip.arrival_from') }} :</div>
              <font-awesome-icon icon="plane" class="mx-50" />
              <span v-if="!isLoading">{{ roundTrip.extendedProps.departure.address.addressName || roundTrip.extendedProps.departure.address.address }}</span>
            </div>

            <div class="d-flex align-items-center">
              <font-awesome-icon icon="clock" class="mx-50" size="1x" />
              <span class="font-weight-bold">{{ $t('trip.event.eta') }} :</span>
              <span>{{ roundTrip.extendedProps.departure.eventDateTime | dateFormatUtc }}</span>
              <span v-if="roundTrip.extendedProps.departure.eventDateTime && roundTrip.extendedProps.departure.eventDateTime" class="mx-50"> - </span>
              <span v-if="roundTrip.extendedProps.departure.eventDateTime">
                {{ roundTrip.extendedProps.departure.eventDateTime | timeFormatUtc }} {{ $t('common.utc') }}
              </span>
            </div>
          </b-overlay>
        </section>

        <!-- DEPARTURE (buyer) -->
        <section v-if="$store.state.auth.selectedOrganization.buyer" class="mb-1">
          <div>
            <div class="font-weight-bold">{{ $t('trip.departure') }} :</div>
            <font-awesome-icon icon="plane" class="mx-50" />
            <span>{{ roundTrip.extendedProps.departure.address.addressName || roundTrip.extendedProps.departure.address.address }}</span>
          </div>
          <div class="d-flex align-items-center">
            <font-awesome-icon icon="clock" class="mx-50" size="1x" />
            <span class="font-weight-bold">{{ $t('trip.event.etd') }}</span>
            <span class="mx-50"> : </span>
            <span>{{ roundTrip.extendedProps.arrival.endsAt | dateFormatUtc }}</span>
            <span class="mx-50"> - </span>
            <span v-if="roundTrip.extendedProps.departure.eventDateTime">
              {{ roundTrip.extendedProps.departure.eventDateTime | timeFormatUtc }} {{ $t('common.utc') }}
            </span>
          </div>
        </section>

        <!-- ARRIVAL (buyer) -->
        <section v-if="$store.state.auth.selectedOrganization.buyer" class="mb-1">
          <div>
            <div class="font-weight-bold">{{ $t('trip.arrival') }} :</div>
            <font-awesome-icon icon="plane" class="mx-50" />
            <span>{{ roundTrip.extendedProps.arrival.address.addressName || roundTrip.extendedProps.arrival.address.address }}</span>
          </div>
          <div v-if="roundTrip.extendedProps.departure.endsAt" class="d-flex align-items-center">
            <font-awesome-icon icon="clock" class="mx-50" size="1x" />
            <span class="font-weight-bold">{{ $t('trip.event.etd') }} : </span>
            <span>{{ roundTrip.extendedProps.arrival.endsAt | dateFormatUtc }}</span>
            <span class="mx-50"> - </span>
            <span>
              {{ roundTrip.extendedProps.arrival.endsAt | timeFormatUtc }} {{ $t('common.utc') }}
            </span>
          </div>
        </section>

        <!--TYPE FLIGHT-->
        <section v-if="roundTrip.extendedProps.step.className === 'TripFlight' && roundTrip.extendedProps.trip.flightMission" class="mb-1">
          <div>
            <span class="font-weight-bold">{{ $t('trip.event.flight_type') }} : </span>
            <span>{{ roundTrip.extendedProps.trip.tripType }}</span>
          </div>
          <div>
            <span class="font-weight-bold">{{ $t('trip.event.flight_mission') }} : </span>
            <span>{{ roundTrip.extendedProps.trip.flightMission }}</span>
          </div>
        </section>

        <!--FLIGHT STATUS-->
        <section v-if="roundTrip.extendedProps.step.className === 'TripFlight'" class="mb-1">
          <div>
            <span class="font-weight-bold">{{ $t('trip.event.flight_type') }} : </span>
            <span>{{ roundTrip.extendedProps.departure.slotNumber }}</span>
          </div>
          <div>
            <span class="font-weight-bold">{{ $t('trip.flight_plan.status') }} : </span>
            <b-badge
              :variant="`${resolveSlotStatusVariant(roundTrip.extendedProps.departure.slotStatus)}`"
              class="font-small-3 mr-50"
            >
              {{ roundTrip.extendedProps.departure.slotStatus | enumTranslate('slot_status') }}
            </b-badge>
          </div>
        </section>

        <section class="mb-1">
          <!--FLIGHT PASSENGERS-->
          <div v-if="roundTrip.extendedProps.trip.crew">
            <span class="font-weight-bold">{{ $tc('trip.event.crew_number', roundTrip.extendedProps.trip.crew) }} : </span>
            <span>{{ roundTrip.extendedProps.trip.crew }}</span>
          </div>
          <div v-if="roundTrip.extendedProps.trip.passengers">
            <span class="font-weight-bold">{{ $tc('trip.event.passenger_number', roundTrip.extendedProps.trip.passengers) }} : </span>
            <span>{{ roundTrip.extendedProps.trip.passengers }}</span>
          </div>
          <div v-if="roundTrip.extendedProps.trip.adults">
            <span class="font-weight-bold">{{ $tc('trip.event.adult_number', roundTrip.extendedProps.trip.adults) }} : </span>
            <span>{{ roundTrip.extendedProps.trip.adults }}</span>
          </div>
          <div v-if="roundTrip.extendedProps.trip.children">
            <span class="font-weight-bold">{{ $tc('trip.event.children_number', roundTrip.extendedProps.trip.children) }} : </span>
            <span>{{ roundTrip.extendedProps.trip.children }}</span>
          </div>
          <div v-if="roundTrip.extendedProps.trip.babies">
            <span class="font-weight-bold">{{ $tc('trip.event.baby_number', roundTrip.extendedProps.trip.babies) }} : </span>
            <span>{{ roundTrip.extendedProps.trip.babies }}</span>
          </div>
        </section>

        <!-- Estimated cost (only if it available, so different of null) -->
        <section v-if="roundTrip.extendedProps.step.stepTotalOrdersBeforeTaxes !== 0" class="mb-1">
          <div class="font-weight-bold mb-25">{{ $t('trip.total_legs.estimated_cost') }} :</div>
          <div>
            <span class="font-weight-bold">{{ $t('trip.total_legs.total_orders_before_tax') }} : </span>
            <span>{{ roundTrip.extendedProps.step.stepTotalOrdersBeforeTaxes | priceFormat }}</span>
          </div>
          <div>
            <span class="font-weight-bold">{{ $t('trip.total_legs.total_orders_excluding_tax') }} : </span>
            <span>{{ roundTrip.extendedProps.step.stepTotalOrdersIncludingTaxes | priceFormat }}</span>
          </div>
        </section>

        <div class="mt-4 mt-lg-5 pt-lg-5">
          <b-button block :to="{ name: 'trip-view', params: { trip_id: roundTrip.extendedProps.trip.id } }">
            <font-awesome-icon icon="info-circle" class="mr-50" />
            {{ $t('action.show') }}
          </b-button>
          <b-button block variant="outline-primary" @click="hide()">
            <font-awesome-icon icon="arrow-left" class="mr-50" />
            {{ $t('action.back') }}
          </b-button>
        </div>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import { defineComponent, ref, watch } from '@vue/composition-api'

import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import UIHelper from '@/helpers/ui'
import { fetchTripOrganizationInfosRequest } from '@/request/globalApi/requests/tripRequest'

export default defineComponent({
  name: 'SidebarTripResume',

  mixins: [UIHelper],

  props: {
    value: {
      type: Boolean,
      required: true,
    },
    roundTrip: {
      type: Object,
      default: () => ({}),
    },
  },

  setup(props, ctx) {
    const { $store } = ctx.root
    const isLoading = ref(false)
    const tripStepEvent = ref({})

    watch(() => props.value, value => {
      if ($store.state.auth.selectedOrganization.seller) {
        if (value) {
          isLoading.value = true
          fetchTripOrganizationInfosRequest(props.roundTrip.extendedProps.trip.id)
            .then(({ data }) => {
              [tripStepEvent.value] = data.tripStepEvents
            })
            .finally(() => {
              isLoading.value = false
            })
        } else {
          isLoading.value = false
          tripStepEvent.value = {}
        }
      }
    })

    return {
      isLoading,
      tripStepEvent,
    }
  },
})
</script>
