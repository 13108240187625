<template>
  <b-form-group :label="localLabel" :label-for="id" label-cols="" label-class="py-0 d-flex align-items-center text-nowrap">
    <b-form-checkbox
      :id="id"
      :checked="value"
      class="custom-control-primary"
      :name="`${id}-name`"
      switch
      v-bind="$attrs"
      v-on="$listeners"
    >
      <span class="switch-icon-left">
        <feather-icon icon="CalendarIcon" />
      </span>
      <span class="switch-icon-right">
        <feather-icon icon="ListIcon" />
      </span>
    </b-form-checkbox>
  </b-form-group>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api'

export default defineComponent({
  name: 'AppCheckboxCalendar',

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: 'calendarMode',
    },
    label: {
      type: String,
      default: null,
    },
  },

  setup(props, ctx) {
    const { $i18n } = ctx.root
    const localLabel = computed(() => props.label || $i18n.t('dashboard.calendar_mode'))

    return {
      localLabel,
    }
  },
})
</script>
